import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import IHero from '../interfaces/hero';

export default class FxCarousel extends React.Component<any, IHero> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <div className="fx-carousel">
        <Carousel
          autoPlay={true}
          showStatus={false}
          showArrows={true}
          showThumbs={false}
          dynamicHeight={true}
          infiniteLoop={true}
          interval={10000}
          transitionTime={1500}
        >
          {data &&
            data.length &&
            data.map((hero: any) => (
              <div key={hero.id}>
                  <img
                    className="fx-carousel-desktop"
                    src={`https://media.graphassets.com/${hero.backgroundDesktop.handle}`}
                  />
                  <img
                    className="fx-carousel-mobile"
                    src={`https://media.graphassets.com/${hero.backgroundMobile.handle}`}
                  />
                  <div className="fx-carousel-content fx-carousel-content--overlay">
                    <div className="fx-carousel-content-text">
                      <span className="fx-title fx-carousel-content-text-heading">{hero.title}</span>
                      <span className="fx-title--subheading fx-carousel-content-text-subheading">{hero.subtitle}</span>
                      <div className="fx-carousel-content-cta">
                        <a href={hero.link} className="fx-btn fx-btn-primary">
                          {hero.linkTitle}
                        </a>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
        </Carousel>
      </div>
    );
  }
}
