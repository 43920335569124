import * as React from 'react';
import { Helmet } from 'react-helmet';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FxCampaign from '../components/campaign';
import FxCarousel from '../components/carousel';
import FxCategories from '../components/categories';
import FxContentHeading from '../components/content-heading';
import FxFooterInfo from '../components/footer-info';
import FxGateway from '../components/gateway';
import FxImageGrid from '../components/image-grid';
import FxLayout from '../components/shared/layout/layout';
interface IIndexPageProps {
  pageContext: any;
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      };
    };
  };
}

export default class IndexPage extends React.Component<IIndexPageProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      homeHeroes,
      keyCategories,
      keyCategoryHeaders,
      imageGrids,
      imageGridHeadings,
      campaigns,
      gateways,
      footers
    } = this.props.pageContext;
    return (
      <FxLayout>
        <Helmet>
          <title>Findex Employee Benefits</title>
          <meta name="Exciting benefits for Findex employees" />
        </Helmet>

        {this.props.pageContext && (
          <div className="fx-l-home">
            {homeHeroes && homeHeroes.length && <FxCarousel data={homeHeroes} />}
            <div className="fx-l-info" id="info">
              {keyCategoryHeaders && keyCategoryHeaders.length && <FxContentHeading data={keyCategoryHeaders} />}
              {keyCategories && keyCategories.length && <FxCategories data={keyCategories} />}
            </div>
            <div className="fx-l-gateway">{gateways && gateways.length && <FxGateway data={gateways} />}</div>
            <div className="fx-l-campaign">{campaigns && campaigns.length && <FxCampaign data={campaigns} />}</div>
            <div className="fx-l-difference">
              {imageGridHeadings && imageGridHeadings.length && <FxContentHeading data={imageGridHeadings} />}
              {imageGrids && imageGrids.length && <FxImageGrid data={imageGrids} />}
            </div>
            <div className="fx-l-footer">{footers && footers.length && <FxFooterInfo data={footers} />}</div>
          </div>
        )}
      </FxLayout>
    );
  }
}
