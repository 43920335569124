import * as React from 'react';
import IGateway from '../interfaces/gateway';
import FxModal from './modal';

export default class FxGateway extends React.Component<any, IGateway> {
  constructor(props: any) {
    super(props);
    this.state = {
      toggleModal: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({ toggleModal: !this.state.toggleModal });
  }

  render() {
    const { data } = this.props;
    return (
      <div className="fx-gateway-container">
        {data.map((gateway: any) => (
          <div className="fx-gateway" key={gateway.id}>
            <div className="fx-content-heading">
              <div className="fx-content-heading-subheading">{gateway.title}</div>
              <hr className="hr--primary" />
              <p className="fx-content-heading-blurb">{gateway.blurb}</p>
            </div>

            <div className="fx-gateway-container">
              <div className="fx-gateway-box">
                <a target="_blank" href="https://findex.dkmblue.com">
                  <img src={`https://media.graphassets.com/${gateway.merch.handle}`} />
                </a>
              </div>

              <div className="fx-gateway-box">
                <img onClick={this.toggleModal} className="fx-gateway-box-image" src={`https://media.graphassets.com/${gateway.rewards.handle}`} />                
              </div>
            </div>
          </div>
        ))}
        <FxModal isVisible={this.state.toggleModal} toggleModal={this.toggleModal} />
      </div>
    );
  }
}
