import * as React from 'react';
import { Component } from 'react';

export default class FXModal extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      country: [],
      toggleModal: true
    };
    this.toggle = this.toggle.bind(this);
    this.countryAU = this.countryAU.bind(this);
    this.countryNZ = this.countryNZ.bind(this);
  }

  toggle() {
    this.props.toggleModal();
  }

  countryAU() {
    this.setState({ country: 'AU' });
    this.props.toggleModal();
  }

  countryNZ() {
    this.setState({ country: 'NZ' });
    this.props.toggleModal();
  }

  render() {
    const { isVisible } = this.props;
    const menu = (
      <div className="fx-modal">
        <div className={`fx-modal-window ${isVisible ? 'fx-modal-window--visible' : ''}`}>
          <div className="fx-modal-window-content">            
            <p className="fx-modal-window-content-heading">Please select your country</p>
            <div className="fx-modal-window-content-buttons">
              <div onClick={this.countryAU} className="fx-modal-window-content-buttons-button">
                <a className="fx-btn fx-btn-primary" href="https://findex.rewardgateway.com.au" target="_blank">Australia</a>
              </div>
              <div onClick={this.countryNZ} className="fx-modal-window-content-buttons-button">
              <a className="fx-btn fx-btn-primary" href="https://findex.rewardgateway.co.nz" target="_blank">New Zealand</a>
              </div>
            </div>
          </div>
        </div>
        <div className="fx-modal-backdrop" onClick={this.toggle} />
      </div>
    );

    return isVisible ? menu : null;
  }
}
