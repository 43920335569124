import * as React from 'react';
import IImageGrid from '../interfaces/image-grid';

export default class FxImageGrid extends React.Component<any, IImageGrid> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <div className="fx-image-grid">        
        <div className="fx-image-grid-container">
          {data.map((image: any) => (
            <div className="fx-image-grid-box" key={image.id}>
              <div className="fx-image-grid-box-image">
                <img src={`https://media.graphassets.com/${image.icon.handle}`}/>
            </div>
                <div className="fx-image-grid-box-title">{image.title}</div>
                <div className="fx-image-grid-box-body">
                  <p>
                  {image.blurb}
                  </p>
                </div>
              </div>
          ))}
        </div>
      </div>
    );
  }
}
