import * as React from 'react';
import FXDropdown from '../components/dropdown';
import ICampaign from '../interfaces/campaign';

export default class FxGateway extends React.Component<any, ICampaign> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data } = this.props;

    return (
      <div className="fx-campaign-container">
        {data.map((campaign: any) => (
          <div className="fx-campaign" key={campaign.id}>
            <div className="fx-campaign-heading">
              <div className="fx-content-heading-subheading">{campaign.heading}</div>
              <hr className="hr--primary" />
            </div>
            <div className="fx-campaign-content">
              <div className="fx-campaign-box">
                <p className="fx-campaign-box-heading">{campaign.subtitle}</p>
                <p className="fx-campaign-box-blurb">{campaign.blurb}</p>

                <div className="fx-campaign-dropdown">
                  {campaign.campaignOption === 'Dropdown' && (
                    <FXDropdown
                      data={campaign}
                      currentPost={campaign.campaignDropdowns}
                      posts={campaign.campaignDropdowns}
                      country={campaign.campaignDropdowns}
                      links={campaign.campaignDropdowns}
                    />
                  )}
                </div>

                {campaign.campaignOption === 'Singular' && (
                  <a className="fx-campaign-box-link" target="_blank" href={campaign.link}>
                    {campaign.linkTitle}
                  </a>
                )}
              </div>
              <div className="fx-campaign-box">
                {campaign.video ? (
                  <div className="fx-campaign-box-video">
                    <iframe
                      width="100%"
                      height="400px"
                      src="https://player.vimeo.com/video/328112118"
                      frameBorder="0"
                      allowFullScreen={true}
                    />
                  </div>
                ) : null}
                {campaign.image && campaign.image.handle ? (
                  <img src={`https://media.graphassets.com/${campaign.image.handle}`} className="fx-campaign-box-image" />
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
