import * as React from 'react';

export default class FxFooterInfo extends React.PureComponent<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data } = this.props;
    const year = new Date().getFullYear();
    return (
      <footer className="fx-l-footer">
        <div className="fx-l-footer-content">
          {data.map((footer: any) => (
            <div className="fx-l-footer-content-copyright" key={footer.id}>
                {year} {footer.copyright}
            </div>
          ))}
        </div>
      </footer>
    );
  }
}
