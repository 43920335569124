import * as React from 'react';
import { Component } from 'react';

interface ITabLinksProps {
  tabs: any;
  name: string;
  url: string;
  id: any;
  tabsOnClick: any;
  countryCode?: string;
}

export default class FXTabButtons extends Component<Partial<ITabLinksProps>, any> {
  constructor(props: any) {
    super(props);

    this.tabsHandler = this.tabsHandler.bind(this);
  }

  tabsHandler() {
    this.props.tabsOnClick(event);
  }

  render() {
    return (
      <div className="fx-tabs fx-tabs--contextual">
        <div className="fx-tabs-container">
          <button
            className={`fx-tabs-item ${this.props.countryCode === 'AU' ? 'fx-tabs-item--active' : ''}`}
            onClick={this.tabsHandler}
            data-country="AU"
          >
            Australia
          </button>
          <button
            className={`fx-tabs-item ${this.props.countryCode === 'NZ' ? 'fx-tabs-item--active' : ''}`}
            onClick={this.tabsHandler}
            data-country="NZ"
          >
            New Zealand
          </button>
        </div>
      </div>
    );
  }
}
