import * as React from 'react';
import FXDropdown from '../components/dropdown';
import FXTabButtons from '../components/tabs-buttons';
import ICategories from '../interfaces/categories';
export default class FxCategories extends React.Component<Partial<ICategories>, any> {
  static defaultProps = {};
  constructor(props: any) {
    super(props);
    this.countryTabChange = this.countryTabChange.bind(this);
    this.state = {
      countryCode: 'AU'
    };
  }
  countryTabChange(event: any) {
    this.setState({ countryCode: event.target.dataset.country });
  }

  render() {
    const { data } = this.props;
    return (
      <div className="fx-categories-container">
        <FXTabButtons tabsOnClick={this.countryTabChange} countryCode={this.state.countryCode} />

        <div className="fx-info-panel">
          <div className="fx-info-panel-container">
            {/* // TODO: needs refactoring */}
            <div className="fx-info-panel-boxes">
              {data
                .filter((c: any) => c.countryVersion === this.state.countryCode)
                .map((category: any) => (
                  <div className="fx-info-panel-box" key={category.id}>
                    <div className="fx-info-panel-box-image">
                      {category.image && category.image.handle && (
                        <img src={`https://media.graphassets.com/${category.image.handle}`} />
                      )}
                    </div>
                    <FXDropdown
                      data={category}
                      links={category.keyCategoryLinkses}
                      currentPost={category}
                      posts={category}
                      country={this.state.countryCode}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
