import * as React from 'react';

interface DropdownProps {
  currentPost?: any;
  posts?: any[];
  data?: any;
  displayMenu?: boolean;
  country?: string;
  links?: any;
  dropdownTitle?: any;
}

export default class FXDropdown extends React.Component<DropdownProps, any> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);
    this.state = {
      displayMenu: false
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event: { preventDefault: () => void }) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  render() {
    const { data } = this.props;
    const { links } = this.props;
    const dropdownTitle = data.dropdownTitle;
    const orderedLinks =
      !!links &&
      !!links.length &&
      links.sort((a: any, b: any) => {
        const dateA = a.order;
        const dateB = b.order;
        return dateA - dateB;
      });

    return (
      <div className="fx-dropdown-container">
        <div className="fx-dropdown">
          <h4 className="fx-dropdown-toggle" onClick={this.showDropdownMenu}>
            <a className="fx-dropdown-toggle-link" role="button">
              {data.title === null || data.title === undefined ? dropdownTitle : data.title}
              <span className="fx-dropdown-toggle-link-icon fx-icon fx-icon-arrow-down" />
            </a>
          </h4>
          {this.state.displayMenu ? (
            <ul className="fx-dropdown-items" key={data.id}>
              {orderedLinks &&
                orderedLinks != null &&
                orderedLinks.map((c: any) => (
                  <div className="fx-dropdown-item-container" key={c.id}>
                    <li className="fx-dropdown-item" key={c.id}>
                      <a href={c.link === '' ? `https://media.graphassets.com/${c.pdf.handle}` : c.link} target="_blank">
                        {c.title}
                      </a>
                    </li>
                  </div>
                ))}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}
