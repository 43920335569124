import * as React from 'react';
import IImageGrid from '../interfaces/image-grid';

export default class FxImageGrid extends React.Component<any, IImageGrid> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <div className="fx-content-heading">
        {data.map((heading: any) => (
          <div className="fx-content-heading-container" key={heading.id}>
            <div className="fx-content-heading-subheading">{heading.title}</div>
            <hr className="hr--primary" />
            <p className="fx-content-heading-blurb">{heading.blurb}</p>
          </div>
        ))}
      </div>
    );
  }
}
